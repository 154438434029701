import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import { IDropdown, IEnumToArray } from "../core/interface/master.interface";

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private route: ActivatedRoute, private router: Router) { }
  public isFieldInvalid(control: string, formGroup: FormGroup) {
    return (
      formGroup.get(control)?.invalid &&
      formGroup.get(control)?.touched
    );
  }

  public isFieldInvalidWithTouched(field: string, formGroup: FormGroup) {
    return (
      formGroup.get(field)?.invalid &&
      formGroup.get(field)?.dirty &&
      formGroup.get(field)?.touched
    );
  }

  convertIntoFormGroup(abstractForm: AbstractControl | null): FormGroup {
    return abstractForm as FormGroup
  }

  public ifExists(obj: any, defaultValue?: any) {
    return obj ? obj : (defaultValue ? defaultValue : '');
  }

  // Function to remove empty values inside an object
  public removeEmpty(object: any): any {
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        if (object[key] instanceof Object) {
          this.removeEmpty(object[key])
        }
        if (object[key] === null || object[key] === '' || object[key] === undefined)
          delete object[key];
      }
    }
    return object;
  }

  /**
   * convert a Date object to s specified format
   * Note: If both params are empty it will return null
   * @param date Accepts a date object
   * @param format Accepts string, used for specifying the format to be converted
   */
  public convertDate(date: Date, format: string): string | null {
    return date && format ? new DatePipe('en').transform(date, format) : null;
  }

  public getEnumArray(enumObj: Record<string, string>): IEnumToArray[] {
    const enumArray = [];
    for (const [enumKey, enumValue] of Object.entries(enumObj)) {
      if (!Number.isNaN(Number(enumKey))) {
        continue;
      }
      enumArray.push({ id: enumKey, value: enumValue });
    }
    return enumArray;
  }
  public getEnumDropdownArray(enumObj: Record<string, string>): IDropdown[] {
    const enumArray = [];
    for (const [enumKey, enumValue] of Object.entries(enumObj)) {
      if (!Number.isNaN(Number(enumKey))) {
        continue;
      }
      enumArray.push({ label: enumKey, value: enumValue });
    }
    return enumArray;
  }
  
  getQueryParam(paramName: string): string {
    return this.route.snapshot.queryParamMap.get(paramName) || '';
  }
  getParam(paramName: string): string {
    return this.route.snapshot.paramMap.get(paramName) || '';
  }

  checkValidAndDirty(control: AbstractControl | null | undefined) {
    return control && control.invalid && control.dirty ? true : false;
  }

  onNavigate(path: string, qParams?: Object | null, newTab?: boolean): void{
    if(newTab){
      let url = this.router.createUrlTree([path], { queryParams: qParams }).toString();
      window.open(url, '_blank');
    }else{
      if(qParams){
        this.router.navigate([path], {
          queryParams: qParams
        })
      }else{
        this.router.navigate([path])
      }
    }
  }

  public scrollToFirstInvalidControl() {
    let firstInvalidControl: any;
    let all = document.getElementsByClassName('ng-invalid');
    for (var i = 0; i < all.length; i++) {
      if (all[i].tagName == 'INPUT') {
        firstInvalidControl = all[i];
        break;
      }
      if (all[i].tagName == 'CKEDITOR') {
        firstInvalidControl = all[i];
        break;
      }
      if (all[i].tagName == 'MAT-FORM-FIELD') {
        firstInvalidControl = all[i];
        break;
      }
      if (all[i].tagName == 'TEXTAREA') {
        firstInvalidControl = all[i];
        break;
      }

    }
    this.containerEl.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth',
    });

    fromEvent(this.containerEl, 'scroll')
      .pipe(debounceTime(0), take(1))
      .subscribe(() => {
        firstInvalidControl.focus();
      });


  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 100;
    const controlElTop = controlEl.getBoundingClientRect().top;
    const absoluteControlElTop = controlElTop + window.scrollY;
    return absoluteControlElTop - labelOffset;
  }

  private get containerEl(): HTMLElement {
    return window as any;
  }

  public convertToDropdownOptions = (data:any[], idKey:string, valueKey:string):IDropdown[] => {
    let options:IDropdown[] = [];
    for (let i = 0; i < data.length; i++) {
      let option = {label:"", value:""};
      option["label"] = data[i][valueKey];
      option["value"] = data[i][idKey];
      options.push(option);
    }
    return options;
  };

  public dropDownDisplayOption(option: IDropdown): string {
    return option ? option.label :"";
  }

  public getActiveItemsOnly(data:any[] ,key?:string):any[]{
    if(key){
      return data.filter((item)=> item[key]=='ACTIVE')
    }
    return data.filter((item)=> item.status=='ACTIVE')
  }
}
